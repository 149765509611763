import React from 'react'
import Layout from '../components/Layout'

import { SitePageContext } from '../graphql-types'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'

interface Props {
  pageContext: SitePageContext
}

const RestaurantTemplate: React.FC<Props> = ({
  pageContext: { restaurant },
}) => {
  const image = getImage(restaurant.bannerImage as ImageDataLike)
  return (
    <Layout>
      <div className="flex justify-between items-center my-3">
        <div className="text-xl font-medium">{restaurant.name}</div>
        <h2>{restaurant.phone}</h2>
      </div>
      <div className="relative">
        {image && <GatsbyImage image={image} alt="pizza" />}
        <div className="absolute top-2 left-5 text-white md:top-5 md:left-6">
          <h1 className="text-2xl tracking-wide mb-4 md:text-4xl">
            We Deliver
          </h1>
          <a
            href={restaurant.onlineOrderLink}
            target="_blank"
            className="bg-black p-3 text-xl block text-center uppercase"
          >
            Order Now
          </a>
        </div>
        <div className="text-center my-2">{restaurant.bannerText}</div>
        <div className="text-xl text-gray-500 font-light text-center my-6">
          {restaurant.categories.map(({ name }, index) => (
            <a href={`#${name}`} key={`${index}-${name}`}>
              {name} {index !== restaurant.categories.length - 1 && '| '}
            </a>
          ))}
        </div>
        <hr />
      </div>
      {restaurant.headline ? (
        <h1 className="mt-4 font-bold text-xl">{restaurant.headline}</h1>
      ) : null}
      {restaurant.description ? (
        <p className="text-justify mt-2">{restaurant.description}</p>
      ) : null}
      <div className="text-center">
        {restaurant.categories.map((category) => (
          <div key={category.name} className="mt-10">
            <div
              className="text-gray-900 text-lg text-4xl mb-5"
              style={{ textShadow: '0 0 3px #FF0000' }}
              id={category.name}
            >
              {category.name}
            </div>
            {category.image && (
              <GatsbyImage
                image={getImage(category.image as ImageDataLike)}
                alt="pizza"
              />
            )}

            {category.description ? <div>{category.description}</div> : null}

            <div className="mt-4">
              {category.menu.map((item) => (
                <div
                  key={item.item}
                  className={` text-gray-900 text-md gap-3 ${
                    item.price ? 'grid grid-cols-2' : 'grid grid-cols-1'
                  }`}
                >
                  {item.item !== category.name ?? (
                    <div className="text-xl font-extrabold">{item.item}</div>
                  )}
                  {item.priceTable ? (
                    <>
                      {item.item ? (
                        <div className="font-extrabold">{item.item}</div>
                      ) : null}
                      <div className="text-sm">{item.description}</div>
                      <div
                        className={`grid gap-3 grid-cols-${item.priceTable?.columns} grid-rows-${item.priceTable?.options?.length}`}
                      >
                        {item.priceTable?.headings?.map((h, i) => (
                          <div key={h + item + i} className="font-extrabold">
                            {h}
                          </div>
                        ))}
                        {item.priceTable?.options?.map((option, optionIdx) => (
                          <React.Fragment key={optionIdx + option.item}>
                            {option.item ? (
                              <div>
                                <div className="font-extrabold">
                                  {option.item}
                                </div>
                                <div className="text-sm">
                                  {option?.description}
                                </div>
                              </div>
                            ) : null}
                            {option.prices.map((price, priceIdx) => (
                              <div key={price + option.item + priceIdx}>
                                {price
                                  ? `$${(Math.round(price * 100) / 100).toFixed(
                                      2
                                    )}`
                                  : ''}
                              </div>
                            ))}
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className="font-extrabold color">{item.item}</div>
                        {item.description ? (
                          <div>{item.description}</div>
                        ) : null}
                      </div>
                      {item.price ? (
                        <div>
                          ${(Math.round(item.price * 100) / 100).toFixed(2)}
                        </div>
                      ) : null}
                    </>
                  )}

                  <div>
                    {item.toppings ? (
                      <div className="my-4">{item.toppings?.join(', ')}</div>
                    ) : null}
                    {item.extraToppings ? (
                      <div className="my-4">
                        {item.extraToppings?.join(', ')}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default RestaurantTemplate
