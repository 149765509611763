import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'

const Layout: React.FC = ({ children }) => {
  return (
    <div className="grid grid-cols-5">
      <div className="hidden lg:block lg:col-span-1 border-gray-300 border-r">
        <Sidebar />
      </div>
      <div className="col-span-5 lg:col-span-4">
        <Header />
        <main className="p-4 max-w-7xl m-auto">{children}</main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
