import * as React from 'react';

export default (props: any) => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M13.5 20h-8c-.827 0-1.5-.673-1.5-1.5v-17C4 .673 4.673 0 5.5 0h8c.827 0 1.5.673 1.5 1.5v17c0 .827-.673 1.5-1.5 1.5zm-8-19a.5.5 0 0 0-.5.5v17a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5h-8z"
      fill={props.fill || 'currentColor'}
    />
    <path
      d="M10.5 3h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM7 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM9.5 18c-.827 0-1.5-.673-1.5-1.5S8.673 15 9.5 15s1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);
