import React from 'react'
import { globalHistory } from '@reach/router'
import { graphql, StaticQuery } from 'gatsby'
import { FooterInfoQuery } from '../graphql-types'
import Clock from '../svg/Clock'
import Phone from '../svg/Phone'
import MapPin from '../svg/MapPin'

const Footer = () => {
  const path = globalHistory.location.pathname

  return (
    <StaticQuery
      query={graphql`
        query FooterInfo {
          allRestaurantsJson {
            edges {
              node {
                onlineOrderLink
                slug
                address1
                address2
                phone
                hours
              }
            }
          }
        }
      `}
      render={(data: FooterInfoQuery) => {
        const restaurant = data.allRestaurantsJson.edges.find(
          (e) => e.node.slug && path.includes(e.node.slug)
        )?.node

        const { hours, phone, address1, address2, onlineOrderLink } =
          restaurant ?? {}

        return (
          <footer className="flex flex-wrap justify-center md:justify-between items-center bg-black p-4 md:p-8 text-gray-300 text-sm md:text-lg">
            <ul>
              <li className="mx-4 flex items-center">
                <Clock />
                <span className="ml-4">{hours}</span>
              </li>
              <li className="mx-4 flex items-center">
                <Phone />
                <span className="ml-4">{phone}</span>
              </li>
              <li className="mx-4 flex items-center">
                <MapPin />
                <span className="ml-4">
                  {address1}, {address2}
                </span>
              </li>
            </ul>
            <a
              href={onlineOrderLink}
              target="_blank"
              className="bg-gray-400 p-3 text-xl text-center uppercase text-white whitespace-nowrap mt-4 md:mt-0 block"
            >
              Order Now
            </a>
          </footer>
        )
      }}
    />
  )
}
export default Footer
